.container.tesimonials__container {
    width: 40%;
}

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem; 
}

.avatar img {
    width: 6rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 0;
    border: 0.3rem solid var(--color-primary);
}

.avatar h5 {
    text-align: center;
    font-size: 1rem;
    font-weight: 300;
}

.avatar a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0  0 0.5rem 0;
    font-size: 1rem;
}

.testimonial {
    background: rgba(28, 28, 51);
    border-radius: 2rem;
    border: 1px solid var(--color-primary);
    user-select: none;
    text-align: justify;
    padding: 2rem;
    margin-bottom: 3rem;
}

.review {
    color: #fafcfb;
    font-size: 0.8rem;
    line-height: 1.5rem;
    font-weight: 100;
    display: block;
    width: 100%;
    margin: 0 auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
}


 /* ----------Media---------- */
  /*       Medium Devices      */
  @media screen and (max-width: 1024px) {
    .container.tesimonials__container {
        width: 40%;
    }
  }
  
  
/* ----------Media---------- */
/*        Small Devices      */
@media screen and (max-width: 600px) {
    .container.tesimonials__container {
        width: var(--container-width-sm);
    }

    .review {
        width: var(--container-width-sm);
    }
  }
  
 
