.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.experience__container > div {
    padding: 2.4rem 4rem;
    border-radius: 2rem;
    border: 1px solid;
    background: rgba(28, 28, 51);
    border-color: var(--color-primary);
    cursor: default;
}

.experience__container > div h3 {
    text-align: center;
    margin-bottom: 1rem;
    color: var(--color-primary);
}

.experience__details h4 {
    font-size: 0.8rem;
    padding-top: 0.4rem;
}

.experience__details small {
    font-size: 0.6rem;
    font-weight: 100;
}

.experience__content {
    display: grid;
    grid-template-columns: 1fr 1fr;

}

.experience__details {
    display: flex;
    gap: 0.5rem;
    line-height: 1.1rem;
    align-items: center;
}

.experience__details-icon {
    font-size: 1rem;
    color: var(--color-primary);
}

  /* ----------Media---------- */
  /*       Medium Devices      */
  @media screen and (max-width: 1024px) {
    .experience__container {
        grid-template-columns:  1fr;
    }

    .experience__container > div {
        width: 100%;
        margin: 0 auto;
    }

    .experience__content {
        padding: 1rem;
    }
  }

/* ----------Media---------- */
/*        Small Devices      */
@media screen and (max-width: 600px) {
    .experience__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .experience__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }

  .experience__details-icon {
        visibility: hidden;
  }

  .experience__content {
    padding: 0rem;
}
}
  

