header {
    padding-top: 6rem;
}

.header__container {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    height: 100%;
    position: relative;
}

/* -----CTA----- */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* -----Header Socials----- */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials a {
    font-size: 1.2rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* -----ME----- */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 22rem;
    position: relative;
    margin: auto;
    margin-top: 5rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 0.1rem 0.5rem 0.1rem 0.5rem;
    transition: all 1000ms ease;    
}

/* -----Get in Touch----- */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 6.5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* -----Media Devices----- */
@media screen and (max-width: 600px) {

    header {
        overflow: hidden;
    }
    .header__socials, .scroll__down {
        display: none;
    }

    .me {
        width: 80%;
        height: 80%;
        position: relative;
        padding: 0.1rem 0.5rem 0.1rem 0.5rem;
    }

}

@media screen and (max-width: 410px) {

    header {
        overflow: hidden;
    }
    .header__socials, .scroll__down {
        display: none;
    }

}
