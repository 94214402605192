.container.contact__container {
    width: 55%;
    display: grid;
    grid-template-columns: 30% 55%;
    gap: 15%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact__option {
    background: rgba(28, 28, 51);
    padding: 1.1rem;
    border-radius: 1rem;
    text-align: center;
    border: 1px solid var(--color-primary);
}

.contact__option-icon {
    font-size: 1.5rem;
}

.contact__option a {
    margin-top: 0.5rem;
    display: inline-block;
    font-size: 0.8rem;
}

/* ---------- FORM ---------- */
form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background: rgba(28, 28, 51);
    border: 1px solid var(--color-primary);
    resize: none;
    color: #f1f1f1;
    transition: var(--transition);
}

input:focus, textarea:focus {
    background: #f1f1f1;
    color: var(--color-bg);
}

input::placeholder, textarea::placeholder {
    color: #e3e3e3;
}

.btn-form {
    padding: 0.7rem 1.4rem;
    font-weight: 700;
}

.swal-button {
    padding: 0.7rem 1.4rem;
    border-radius: 0.2rem;
    background: var(--color-bg);
    font-size: 1rem;
    border: 1px solid var(--color-bg);
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
  }


/* ----------Media---------- */
  /*       Medium Devices      */
@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* ----------Media---------- */
/*        Small Devices      */
@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-sm);
    }
}