footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-primary);
    padding: 0.3rem 2rem;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 1.7rem;
}

footer a {
    color: var(--color-bg);
}

.left__side {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer__logo {
    display: flex;
    width: 50px;
    height: 50px;
}

.footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.footer__socials a {
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.5rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
    transition: var(--transition);
}

.footer__socials a:hover {
    background: var(--color-white);
    color: var(--color-bg);
}

.footer__copyright {
    color: var(--color-bg);
    padding-left: 1rem;
}

/* ----------Media---------- */
/*        Small Devices      */
@media screen and (max-width: 600px) {
    footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

    }

    .left__side {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}