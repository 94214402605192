.portfolio__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.portfolio__item {
    background: rgba(28, 28, 51);
    padding: 1rem;
    border-radius: 2rem;
    border: 1px solid var(--color-primary);
}

.portfolio__item h3 {
    margin: 1rem 0 1rem;
}

.portfolio__item-image img{
    border-radius: 0.5rem;
    overflow: hidden;
}

a {
    font-size: 0.8rem;
}

h4 {
    font-size: 0.9rem;
    margin-top: 1rem;
    color: var(--color-primary);
}

p {
    font-weight: 100;
}

.btn-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn {
    padding: 0.3rem 0.6rem;
}


.btn-more {
    margin: 1.5rem 0;
    display: flex;
    justify-content: center;
}

.btn-show {
    background: var(--color-primary);
    padding: 0.8rem 1.6rem;
    border-radius: 1rem;
    cursor: pointer;
    transition: var(--transition);
    font-size: 1rem;
}

.btn-show:hover {
    background: var(--color-white);
    color: var(--color-bg);
}

.tech__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.tech__list li {
    display: flex;
    gap: 0.3rem;
    padding: 0 0.5rem 0.5rem 0;
}

.tech__list-icon {
    color: var(--color-primary);
    font-size: 1.1rem;
}

.tech__list p {
    font-size: 0.8rem;
    padding-right: 0.5rem;
    margin-top: 0.1rem;
}
  
  /* ----------Media---------- */
  /*       Medium Devices      */
  @media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
  }

  /* ----------Media---------- */
/*        Small Devices      */
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
  
  